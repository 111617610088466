<script setup>
import { onMounted, ref, watch } from 'vue';
import { MRegister, MCard, getOrCreateFilter } from "o365-mobile";
import { confirm } from 'o365-vue-services';
import SignatureModal from 'sja.vue.libraries.components.SignatureModal.vue';
import { OPersonsLookup } from "o365-system-lookups";
import { procRemoveSignature, procSignSJA, reloadKey, participants, allParticipantsLength } from 'sja.vue.libraries.modules.utilities.js'
import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
import { userSession } from "o365-modules";
import OModal from 'o365.vue.components.Modal.vue';

const props = defineProps({
    sjaID: Number,
    itemID: Number,
    stepID: Number,
    readOnly: Boolean,
    responsibleID: Number
});

const dsParticipants = getOrCreateDataObject({
    id: `dsParticipants_${props.sjaID}`,
    uniqueTable: 'atbv_SJA_SJAParticipants',
    selectFirstRowOnLoad: false,
    maxRecords: -1,
    viewName: 'aviw_SJA_SJAParticipants',
    allowUpdate: true,
    allowInsert: true,
    allowDelete: true,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'SJA_ID', },
        { name: 'Signed', },
        { name: 'SignedBy_ID', },
        { name: 'SignedByName', },
        { name: 'Position', },
        { name: 'Person_ID', },
        { name: 'FirstName', },
        { name: 'LastName', },
        { name: 'Name', },
        { name: 'MobileNo', },
        { name: 'Email', },
        { name: 'FileName', },
        { name: 'FileSize', },
        { name: 'FileUpdated', },
        { name: 'FileRef', },
        { name: 'FileSrc', },
        { name: 'Extension', },
        { name: 'Represents', },
        { name: 'LastSent' }
    ],
    whereClause: "SJA_ID = " + props.sjaID
});
let dsSJA = getDataObjectById(`dsSJA${props.itemID ? '_' + props.itemID + (props.stepID ? '_' + props.stepID : '') : ''}`);

let currentParticipant = ref(null);
let newPerson = ref({});
const modalRef = ref(null);

const filter = getOrCreateFilter({
    id: "Participants",
    // registerID: Url.getParam('ID'),
    dataObject: dsParticipants,
    searchFields: ["Name", "MobileNo", "Email"],
    requiredFields: [],
    fields: [
        { type: "Checkbox", field: "c_includeSigned", name: $t("Have signed"), checkedClause: "Signed IS NOT NULL" },
        { type: "Checkbox", field: "c_includeNotSigned", name: $t("Have not signed"), checkedClause: "Signed IS NULL" },
        { type: "Checkbox", field: "c_onlyMyself", name: $t('Show only myself'), checkedClause: `Person_ID = ${userSession.personId}` },
        { type: "Distinct", field: "Name", name: $t("Names") }
    ],
    orders: [
        { name: $t("Alphabetically (A-Z)"), order: [{ "Name": "Asc" }] },
        { name: $t("Alphabetically (Z-A)"), order: [{ "Name": "Desc" }] }
    ]
});

onMounted(() => {
    document.querySelectorAll('.afm-container').forEach(element => {
        element.style.position = 'static'
    });
    allParticipantsLength.value = dsParticipants.data.length;
})

async function deleteParticipant(row) {
    await confirm({
        title: $t("Remove Participant"),
        message: `${$t('Are you sure you wish to remove')} ${row.Name} ${$t('as a participant?')}`,
        btnTextOk: $t("Yes"),
        btnTextCancel: $t("No"),
    }).then(async () => {
        await row.delete()
    }).catch(() => {
    });
}

function blankParticipant() {
    dsParticipants.createNew({ SJA_ID: props.sjaID }, false);
    document.querySelector('.app-container').scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    modalRef.value.hide();
}

async function signSJA(row) {
    await procSignSJA.execute({ ParticipantKey: row.PrimKey });
    const dataObject = getDataObjectById(row.dataObjectId)
    await dataObject.load()
}

async function deleteSign(row) {
    confirm({
        title: `${$t("Delete")} ${$t("Signature")}`,
        message: `${$t('Are you sure you wish to remove the signature for')} ${row.FirstName} ${row.LastName}?`,
        btnTextOk: $t("Yes"),
        btnTextCancel: $t("No")
    }).then(async () => {
        await procRemoveSignature.execute({ ParticipantKey: row.PrimKey });
        dsParticipants.load();
    }).catch(() => {

    });
}

function setPerson(row) {
    currentParticipant.value = row;
}

async function loadParticipants() {
    await dsSJA.load();
    await dsParticipants.load();
}

defineExpose({
    loadParticipants,
    setNewPerson
})

async function setNewPerson(person) {
    newPerson.value = {
        SJA_ID: props.sjaID,
        Person_ID: person.ID,
        FirstName: person.FirstName,
        LastName: person.LastName,
        Represents: person.Represents,
        MobileNo: person.MobileNo,
        Email: person.Email
    };

    await dsParticipants.createNew(newPerson.value, false);
    await dsParticipants.save();

    document.querySelector('.app-container').scrollTo({
        top: 0,
        behavior: 'smooth'
    });

    modalRef.value.hide();
}

watch(dsParticipants, () => {
    const selectedParticipants = dsParticipants.selectionControl.selectedRows;

    if (selectedParticipants.length > 0) {
        participants.value = selectedParticipants;
    } else {
        participants.value = dsParticipants.data;
    }
});

dsParticipants.on("BeforeSave", async function (options, row) {
    if (row.FirstName === '\n') {
        options.cancelEvent = true
        row.FirstName = null;
        dsParticipants.save();
    }
    if (row.LastName === '\n') {
        options.cancelEvent = true
        row.LastName = null;
        dsParticipants.save();
    }
});

</script>

<template>
    <div>
        <MRegister :dataObject="dsParticipants" :filter="filter" :title="$t('Participants')"
            :whereClause="`SJA_ID = ${props.sjaID}`" hideFolderView>
            <template #card="{ row }">
                <MCard>
                    <div class="d-flex flex-row pt-1">
                        <div class="align-middle ms-auto me-3">
                            <span class="position-absolute" @click="deleteParticipant(row)"><i
                                    class="text-black bi bi-x-lg"></i></span>
                        </div>
                    </div>

                    <div class="font-1 fw-medium text-primary mb-2 d-flex align-items-center">
                        <div v-if="!row.Person_ID && props.readOnly == false" class="w-100">
                            <span>{{ $t('First Name') }}:</span>
                            <OContentEditable :class="{ 'fw-bold fs-5': row.IsHeader }" class="w-100 h-100 p-1 text-break form-control"
                                :isEditable="!row.Person_ID && props.readOnly == false" v-model="row.FirstName"
                                placeholder="*" @blur="dsParticipants.save()" />

                            <span>{{ $t('Last Name') }}:</span>
                            <OContentEditable :class="{ 'fw-bold fs-5': row.IsHeader }" class="w-100 h-100 p-1 text-break form-control"
                                :isEditable="!row.Person_ID && props.readOnly == false" v-model="row.LastName"
                                placeholder="*" @blur="dsParticipants.save()" />
                        </div>
                        <div v-else class="w-75">
                            {{ $t('Name') }}: {{ row.Name }}
                        </div>
                    </div>

                    <div>
                        <div class="mb-2">
                            {{ $t('Represents') }}:
                            <OContentEditable class="w-100 h-100 p-1 text-break" :isEditable="!row.Person_ID && props.readOnly == false" v-model="row.Represents" :class="!row.Person_ID && props.readOnly == false ? 'form-control' : ''" @blur="dsParticipants.save()" />
                        </div>
                        <div class="mb-2">
                            {{ $t('Position') }}:
                            <OContentEditable class="w-100 h-100 p-1 text-break" :isEditable="!row.Person_ID && props.readOnly == false" v-model="row.Position" :class="!row.Person_ID && props.readOnly == false ? 'form-control' : ''" @blur="dsParticipants.save()" />
                        </div>
                        <div class="mb-2">
                            {{ $t('Mobile No') }}:
                            <OContentEditable class="w-100 h-100 p-1 text-break" :isEditable="!row.Person_ID && props.readOnly == false" v-model="row.MobileNo" :class="!row.Person_ID && props.readOnly == false ? 'form-control' : ''" @blur="dsParticipants.save()" />
                        </div>
                        <div class="mb-2">
                            {{ $t('Email') }}:
                            <OContentEditable class="w-100 h-100 p-1 text-break" :isEditable="!row.Person_ID && props.readOnly == false" v-model="row.Email" :class="!row.Person_ID && props.readOnly == false ? 'form-control' : ''" @blur="dsParticipants.save()" />
                        </div>
                        <div class="mb-2" :class="(userSession.personId === props.responsibleID || row.Person_ID === userSession.personId) && !row.Signed && (!props.stepID || props.itemID) ? 'd-flex align-items-center' : ''">
                            {{ $t('Sign') }}:
                            <div>
                                <i :title="$t('This person has signed')" v-if="row.Signed || row.FileName"
                                    class="fs-5 text-success px-1 bi bi-check2"></i>
                                <span data-format="dd.MM.yyyy HH:mm"> {{ $formatDate(row.Signed, 'General Date Short Time')
                                }}</span>
                                <div v-if="(userSession.personId === props.responsibleID || row.Person_ID === userSession.personId) && !row.Signed && (!props.stepID || props.itemID)"
                                    class="d-flex m-auto">
                                    <button class="mx-1 btn btn-primary table-button btn-sm" :title="$t('Sign the SJA')"
                                        @click="signSJA(row)"
                                        v-if="(userSession.personId === props.responsibleID || row.Person_ID === userSession.personId) && !row.Signed && !row.isNewRecord">
                                        {{ $t('Sign') }} </button>
                                    <button class="mx-1 btn btn-primary table-button btn-sm"
                                        :title="$t('Sign the SJA with a signature')" v-target:signatureModal
                                        @click="setPerson(row)"
                                        v-if="(userSession.personId === props.responsibleID || row.Person_ID === userSession.personId) && !row.Signed && !row.isNewRecord">
                                        {{ $t('Signature') }} </button>
                                </div>
                                <span v-if="row.Signed"> &nbsp; {{ $t('by') }} &nbsp; </span>
                                <span v-if="row.Signed"> {{ row.SignedByName }} &nbsp; </span>
                                <span v-if="row.FileRef">
                                    <img height="40" :src='row.FileSrc'>
                                </span>
                                <i @click="deleteSign(row)" type="button" :title="$t('Remove signature')"
                                    v-if="(userSession.personId === props.responsibleID || row.Person_ID == userSession.personId) && row.Signed && props.readOnly == false"
                                    class="fs-5 px-1 text-black bi bi-x-lg"></i>
                            </div>
                        </div>
                        <div>
                            {{ $t('Last sent message') }}:
                            <div>
                                {{ $formatDate(row.LastSent, 'Short Date') }}
                            </div>
                        </div>
                    </div>
                </MCard>
            </template>
        </MRegister>
    </div>

    <SignatureModal :sjaID="props.sjaID" :personRow="currentParticipant"></SignatureModal>

    <div v-if="!props.readOnly" class="dropup">
        <a @click="modalRef.show" class="d-flex justify-content-center align-items-center text-white afm-floating-button">
            <i class="fas fa-plus" style="font-size: 1.25em;" />
        </a>
    </div>

    <OModal ref="modalRef">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add new participant</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <OPersonsLookup :bind="sel => { setNewPerson(sel) }">
                        <template #target="{ target }">
                            <div :ref="target" class="form-control d-flex justify-content-center">
                                {{ $t('Search from lookup') }}
                            </div>
                        </template>
                    </OPersonsLookup>
                    <button class="mt-2 form-control" @click="blankParticipant()">
                        {{ $t('Or add new') }}
                    </button>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ $t('Cancel') }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<style scoped>
.afm-floating-button {
    position: fixed;
    right: 0.3rem;
    bottom: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 999px;
    text-decoration: none;
    box-shadow: 4px 8px 16px rgb(0%, 0%, 0%, 10%);
    background-color: #3575B3;
}
</style>